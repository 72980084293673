import { atom } from 'recoil';

export const isShowModalState = atom({
  key: 'isShowModalState',
  default: false,
});


export const isShowModalPosition = atom({
  key: 'isShowModalPosition',
  default: 0,
});
