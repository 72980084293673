import React, { forwardRef } from "react"
import { useRef, useState, useEffect } from 'react'
import { isShowModalState , isShowModalPosition } from './ui.state.js';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { ReactComponent as Logo } from './svg/logo.svg';
import { ReactComponent as Close } from './svg/close.svg';
import PortfolioData from "./json/portfolioData.json";

function Modal(props) {

  const setModal = useSetRecoilState(isShowModalState);
  const ShowModalPosition = useRecoilValue(isShowModalPosition);
  const [lineHeight, setLineHeight] = useState(0);

  useEffect(() => {

    const divHeight = document.getElementById('ContentsWrap').clientHeight;
    document.getElementById(ShowModalPosition).scrollIntoView();
    setLineHeight(divHeight);
  })

  return (
    <div className="modal">
      <button className="closeBtn" onClick={() => { setModal(false); }}><Close className="closeIcon" fill="#000" /></button>

      <div className="modalWrap">
        <div className="contentsWrap" id="ContentsWrap">
          <div className="line"   style={{ height:lineHeight}}></div>
          <Logo className="modalLogo" />

          {PortfolioData.map(function (data) {
            return (
              <div className="Portfolio" id={data.id}>
                <div className="titleWrap">
                  <div className="dot"></div>
                  <p className="date">{data.date}</p>
                  <h4 className="title">{data.titleDeview}</h4>
                </div>
                <div className="etc">
                  <p>Client</p>
                  <h6>{data.client}</h6>
                </div>
                {data.URL && (<div className="etc">
                  <p>URL</p>
                  <a href={data.URL} target="_blank">{data.URL}</a>
                </div>)}                
                <h5 className="dec">{data.Dec}</h5>

                {data.imgURL.map(function (img) {
                  return (
                    <div className="poImgWrap">
                      <img className="poImg" src={img} />
                    </div>
                  )
                })}
                {data.video.map(function (video) {
                  return (
                    <div className="videoWrap">
                      <video controls ><source src={video} type="video/mp4" /></video>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )

} 

export default Modal
