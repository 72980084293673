import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil';
import './styles.css'
import App from "./App"



function Root() {
  return (
    <RecoilRoot>
      <App />
    </RecoilRoot>
  )
}

createRoot(document.getElementById('root')).render(<Root />)
